module.exports = {
  getData: getData
};

/**
 * Parses the JSON data from a script tag that matches the id.
 *
 * @param {String} id
 * @returns {Object}
 */
function getData(id) {
  var element = document.getElementById(id);
  if (!element || !isCorrectType(element)) {
    return;
  }

  return JSON.parse(element.innerHTML);
}

function isCorrectType(element) {
  return (
    element.tagName.toLowerCase() === 'script' &&

    // Handle both application/json tags and text/ng-data tags
    // (which were initially intended for the legacy Angular app).
    (element.type === 'application/json' ||
     element.type === 'text/ng-data')
  );
}
